import React from 'react'
import Marco from '../components/marco'
import Container from '@material-ui/core/Container'
import Minipaginas from '../components/minipaginas'
import Minipagina from '../components/minipagina'
import FotoConLeyenda from '../components/foto-con-leyenda'
import Comentarios from '../components/comentarios'
import TextoEnMinipagina from '../components/texto-en-minipagina'
import { Tips } from '../components/estilos/estilos-de-articulo'
import EspaciadorVertical from '../components/espaciador-vertical'
import { graphql } from 'gatsby'
import Navegar from '../components/navegar'

export const query = graphql`
  {
    allTipsLeonardoExpressYaml {
      edges {
        node {
          nombre
          origen {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

const TipsLeonardoExpress = ({ data, location }) => {
  // eslint-disable-next-line no-unused-vars
  const toFluid = nombre => {
    const edges = data.allTipsLeonardoExpressYaml.edges
    const found = edges.find(x => x.node.nombre === nombre)
    const fluid = found.node.origen.childImageSharp.fluid
    return fluid
  }
  return (
    <Marco>
      <Tips>
        <Container maxWidth="xl">
          <Minipaginas>
            <Minipagina>
              <FotoConLeyenda fluid={toFluid('imgblogtleonardoexpress1leonardoexpress1jpg')} />
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                <h2>De Fiumicino a Roma</h2>
                <p>
                  ¡Ya estoy en el Aeropuerto Leonardo da Vinci! Ese que llamamos Fiumicino por la
                  localidad donde se encuentra ¿Ahora, cómo llego a Roma? Opciones hay muchas pero
                  la que más me gusta es tomar el tren para turista, el Leonardo Express.
                </p>
                <h3>¿Qué es el Leonardo express? </h3>
                <p>
                  Es un tren directo desde el aeropuerto Leonardo da Vinci hasta Termini, la
                  estación terminal de trenes de Roma.
                </p>
                <p>¡En 32 minutos estás en el centro de Roma! </p>
                <p>
                  Allí pasan 2 líneas de subtes y además hay una terminal de ómnibus y, por
                  supuesto, parada de taxis.
                </p>
                <p>
                  No necesitás reservar. Tal vez para hora pico convenga comprar el pasaje online.
                  En los horarios en que llegué nunca tuve problemas.
                </p>
              </TextoEnMinipagina>
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                <h3>¿Cómo lo encuentro?</h3>
                <p>
                  En el aeropuerto sigo las indicaciones (cartel amarillo con el dibujo del tren) y
                  llego sin problemas a la estación de trenes. En algunas partes hay cintas
                  transportadoras en el piso para ir más rápido y con comodidad. No te sorprendas si
                  tienes que bajar escaleras mecánicas, caminar, y volver a subir escaleras
                  mecánicas. También puedes buscar ascensores. Es que la estación, como es de
                  esperar está en otro edificio.
                </p>
                <p>
                  Como está diseñado especialmente para turistas con valijas, no tiene escalones
                  para subir y tiene espacios especiales para dejar las valijas.
                </p>
                <p>
                  {' '}
                  Desde las amplias ventanas puedes ir viendo el paisaje campestre. Todo un placer.
                </p>
              </TextoEnMinipagina>
            </Minipagina>
            <Minipagina>
              <FotoConLeyenda fluid={toFluid('imgblogtleonardoexpressvalijaseneltrenjpg')} />
            </Minipagina>
          </Minipaginas>
          <Navegar location={location} />
          <Comentarios masPalidos />
          <EspaciadorVertical altura={36} />
        </Container>
      </Tips>
    </Marco>
  )
}
export default TipsLeonardoExpress
